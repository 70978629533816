// THEME
export const CHANGE_THEME = 'CHANGE_THEME';

// AUTHORIZATION
export const REGISTER_USER = "REGISTER_USER";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const AUTH_RESET = "AUTH_RESET";

// CAMPAIGNS
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const GET_USER_CAMPAIGNS = "GET_USER_CAMPAIGNS";
export const GET_ALL_CAMPAIGNS = "GET_ALL_CAMPAIGNS";
export const GET_CAMPAIGN_ANALYSIS = "GET_CAMPAIGN_ANALYSIS";
export const GET_CAMPAIGN_GIVERS = "GET_CAMPAIGN_GIVERS";
export const LIST_CAMPAIGNS = "LIST_CAMPAIGNS";
export const CAMPAIGN_RESET = "CAMPAIGN_RESET";
export const DASHBOARD_CAMPAIGNS = "DASHBOARD_CAMPAIGNS";
