import { themeReducer } from './theme';
import { authReducer } from './auth';
import { campaignReducer } from './campaign';

export const reducer = (
  { 
    theme, auth, campaign
  },
  action,
) => ({
  theme: themeReducer(theme, action),
  auth: authReducer(auth, action),
  campaign: campaignReducer(campaign, action),
});
