
export const campaign = {
    addCampaign: null,
    updateCampaign: null,
    getAllCampaigns: null,
    getUserCampaign: null,
    getCampaignAnalysis: null,
    getCampaignGivers: null,
    listCampaigns: null,
    dashboardCampaign: null,
}